import { useState } from 'react'
import withAside from 'hocs/withAside'
import Container from 'components/Container'
import TabsOrders from 'components/TabsOrders'
import DataCard from 'components/DataCard'
// import LinksCard from 'components/LinksCard'
import { useAuth } from 'clients/auth.client'
import FormDropDown from 'forms/FormDropDown'
import {
  // GetSocialMediaLinksQuery,
  // GetSocialMediaLinksDocument,
  GetClientsDocument,
  GetHiredUsersDocument,
  GetClientsQuery,
  GetHiredUsersQuery,
  useProfileMetricsQuery,
  Timestamp_Comparison_Exp,
  useGetCurrentOrdersMetricsQuery,
  useGetOrdersHistoryMetricsQuery,
  GetCurrentOrdersMetricsQuery,
  Wui_SessionEventType_Enum,
} from 'generated/graphql'
import { DASHBOARD_CATEGORIES, GENERIC_ERROR_MESSAGE } from 'globalConstants'
import ActionsList from 'components/ActionsList'
import MissingLegalInformation from 'components/MissingLegalInformation'
import dayjs from 'dayjs'
import patternMatching from 'utils/patternMatching'
import ErrorMessage from 'components/ErrorMessage'
import SimpleQuery from 'components/SimpleQuery'
import { Swiper, SwiperSlide } from 'swiper/react'
import InfluencerCard from 'components/InfluencerCard'
import Breakpoint from 'components/Breakpoint'
import Loading from 'components/Loading'
import take from 'lodash/fp/take'
import uniqBy from 'lodash/uniqBy'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { GetStaticPropsContext } from 'next'
import serverSideTranslationsWithDefaultNS from 'utils/serverSideTranslationsWithDefaultNS'
import { useTranslation } from 'next-i18next'
import ShortcutIconLinks from 'assets/icons/icons8-webpage-click-50.svg'
import ShortcutIconDemographics from 'assets/icons/icons8-crowd-50.svg'
import ShortcutIconProfile from 'assets/icons/icons8-customer-50.svg'
import ShortcutIconPackages from 'assets/icons/icons8-cardboard-box-50.svg'
import ShortcutIconPriceRequest from 'assets/icons/icons8-siren-50.svg'
import ShortcutIconInbox from 'assets/icons/icons8-inbox-50.svg'

import styles from './index.module.scss'

export async function getStaticProps({ locale }: GetStaticPropsContext) {
  return {
    props: {
      ...(await serverSideTranslationsWithDefaultNS(locale, ['home'])),
    },
  }
}

const Homepage = () => {
  const { t } = useTranslation()

  return (
    <>
      <Container>
        <div className="pt-12">
          <h2 className="pb-4 text-2xl md:text-3xl md:pb-8 font-bold text-secondary">
            {t<string>('home:title_quick-access')}
          </h2>
          <Shortcuts />
        </div>

        <div className="pt-12">
          <h2 className="pb-4 text-2xl md:text-3xl md:pb-8 font-bold text-secondary">
            {t<string>('home:title_metrics')}
          </h2>
          <Dashboard />
        </div>
      </Container>
    </>
  )
}

export default Homepage

const Shortcuts = () => {
  const { t } = useTranslation()
  const { account } = useAuth()

  const ITEMS = [
    {
      id: 'links',
      to: '/profile/links',
      label: t<string>('menu:linkLinks'),
      Icon: ShortcutIconLinks,
    },
    account?.isSeller && {
      id: 'demographics',
      to: '/profile/demographics',
      label: t<string>('menu:linkAudience'),
      Icon: ShortcutIconDemographics,
    },
    {
      id: 'profile',
      to: account?.username ? `/${account?.username}` : '/profile/edit',
      label: t<string>('menu:linkProfile'),
      Icon: ShortcutIconProfile,
    },
    account?.isSeller && {
      id: 'packages',
      to: '/profile/packages',
      label: t<string>('menu:linkPackages'),
      Icon: ShortcutIconPackages,
    },
    {
      id: 'price-requests',
      to: '/price-requests',
      label: t<string>('menu:linkPriceRequests'),
      Icon: ShortcutIconPriceRequest,
    },
    {
      id: 'inbox',
      to: '/notifications',
      label: t<string>('menu:Inbox'),
      Icon: ShortcutIconInbox,
    },
  ].filter(Boolean) as {
    id: string
    to: string
    label: string
    Icon: any
  }[]

  return (
    <ul className="grid gap-4 md:grid-cols-4 md:gap-8">
      {ITEMS.map(({ id, to, label, Icon }) => (
        <li key={`shortcut-${id}`}>
          <Link
            href={to}
            className={[
              'block flex items-center p-4 bg-white border border-gray-300 rounded-md md:flex-col md:hover:shadow-lg',
              styles.shortcutButton,
            ].join(' ')}
          >
            <span className="flex-grow pl-2 text-xl md:order-2 md:pl-0 md:pt-6">
              {label}
            </span>
            <span className="md:order-1">
              <Icon className="w-12 h-12 fill-primary" />
            </span>
          </Link>
        </li>
      ))}
    </ul>
  )
}

const Aside = () => {
  return (
    <div className="md:py-5">
      <div style={{ maxHeight: '700px' }}>
        <div className="pr-1">
          <TabsOrders />
        </div>
      </div>
    </div>
  )
}

const Dashboard = withAside(
  () => {
    const { isAuthenticated, account } = useAuth()

    if (!isAuthenticated) {
      return null
    }

    return (
      <div className="w-full md:my-2">
        <Container leftAndRight={false}>
          <ActionsList />
          <MissingLegalInformation />

          <div className="flex flex-col gap-4 md:mb-5">
            {account.isSeller && <ProfileViews />}

            {/* ORDERS */}
            <div>
              <CurrentOrdersMetrics />
              <HistoryOrdersMetrics />
            </div>

            {/* LINKS */}
            {/* {account.isSeller && <Links />} */}

            {/* TAB ORDERS */}
            <div className="md:hidden">
              <TabsOrders />
            </div>
          </div>

          <div className="space-y-4">
            {/* My Clients */}
            <SimpleQuery
              query={GetClientsDocument}
              variables={{
                id: account?.id,
              }}
              Loading={() => <Loading $size="lg" $type="logo" />}
              Success={({ data }: { data: GetClientsQuery }) => {
                const { WUI_Order } = data

                if (WUI_Order.length === 0) {
                  return null
                }

                return (
                  <div>
                    <div className="text-lg font-bold pb-3 mt-3 md:mt-0">
                      My clients
                    </div>
                    <UserSwiper data={WUI_Order} />
                  </div>
                )
              }}
            />

            {/* My Influencers */}
            <SimpleQuery
              query={GetHiredUsersDocument}
              variables={{
                id: account?.id,
              }}
              Loading={() => <Loading $size="lg" $type="logo" />}
              Success={({ data }: { data: GetHiredUsersQuery }) => {
                const { WUI_Order } = data

                if (WUI_Order.length === 0) {
                  return null
                }

                return (
                  <div>
                    <div className="text-lg font-bold pb-3 mt-3 md:mt-0">
                      My Influencers
                    </div>
                    <UserSwiper data={WUI_Order} />
                  </div>
                )
              }}
            />
          </div>
        </Container>
      </div>
    )
  },
  Aside,
  {
    position: 'right',
  },
)

const filterDateHandler = (key: string) => {
  const today = dayjs(new Date().toISOString()).hour(0).minute(0)
  const startOfToday = today.hour(0).second(0).minute(0)
  const filterDate = patternMatching<string, { _gte: string; _lte?: string }>([
    ['Today', { _gte: startOfToday.toISOString() }],
    [
      'Yesterday',
      {
        _gte: startOfToday.subtract(1, 'days').toISOString(),
        _lte: today
          .subtract(1, 'days')
          .hour(23)
          .minute(59)
          .second(59)
          .toISOString(),
      },
    ],
    ['This Week', { _gte: startOfToday.subtract(7, 'days').toISOString() }],
    ['This Month', { _gte: startOfToday.subtract(1, 'month').toISOString() }],
    ['This Week', { _gte: startOfToday.subtract(1, 'week').toISOString() }],
    ['This Year', { _gte: startOfToday.subtract(1, 'year').toISOString() }],
  ])(key)

  return filterDate || { _gte: startOfToday.toISOString() }
}

const CurrentOrdersMetrics = () => {
  const { t } = useTranslation()
  const { account } = useAuth()
  const [{ fetching, data, error }] = useGetCurrentOrdersMetricsQuery({
    variables: { userID: account?.id },
  })

  if (fetching) return null

  if (error) {
    return (
      <div className="text-center">
        <ErrorMessage>
          {error.message || t('common:genericErrorMessage')}
        </ErrorMessage>
      </div>
    )
  }

  if (!data) return null

  return <CurrentOrdersMetricsDisplay data={data} />
}

const CurrentOrdersMetricsDisplay = ({
  data,
}: {
  data: GetCurrentOrdersMetricsQuery
}) => {
  const { t } = useTranslation('home')
  return (
    <div>
      <div className="flex items-center justify-between w-full py-2">
        <div className="text-lg font-bold">{t('metrics_orders_title')}</div>
      </div>
      <div className="flex gap-5 pb-5">
        <Link href="/orders#current" className="w-full">
          <DataCard
            data={data.actives.aggregate?.count ?? 0}
            name="Active Orders"
            price={data.actives.aggregate?.sum?.price ?? 0}
          />
        </Link>
        <Link href="/orders#current" className="w-full">
          <DataCard
            data={data.pending.aggregate?.count ?? 0}
            name="Pending Orders"
            price={data.pending.aggregate?.sum?.price ?? 0}
          />
        </Link>
      </div>
    </div>
  )
}

const HistoryOrdersMetrics = () => {
  const { t } = useTranslation('home')
  const { account } = useAuth()
  const [whereClosedAt, setWhereClosedAt] = useState(filterDateHandler('Today'))
  const [{ fetching, data, error }] = useGetOrdersHistoryMetricsQuery({
    variables: { userID: account?.id, whereClosedAt },
  })

  return (
    <div>
      <div className="flex items-center justify-between w-full py-2">
        <div className="text-lg font-bold">
          {t('metrics_ordersHistory_title')}
        </div>
        <div className="w-32">
          <FormDropDown
            $disableLabel
            $type="normal"
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
              setWhereClosedAt(filterDateHandler(e.target.value))
            }
          >
            {DASHBOARD_CATEGORIES.map((option) => (
              <option value={option} key={`audience_ages-${option}`}>
                {option}
              </option>
            ))}
          </FormDropDown>
        </div>
      </div>
      <div className="flex gap-5 pb-5">
        {error && (
          <div className="text-center">
            <ErrorMessage>
              {error?.message || GENERIC_ERROR_MESSAGE}
            </ErrorMessage>
          </div>
        )}

        {!fetching && !error && !data && (
          <div className="text-center">
            <ErrorMessage>{GENERIC_ERROR_MESSAGE}</ErrorMessage>
          </div>
        )}

        {data && (
          <>
            <Link href="/orders#past" className="w-full">
              <DataCard
                data={fetching ? '---' : data.cancelled.aggregate?.count ?? 0}
                name="Cancelled Orders"
                price={
                  fetching ? '---' : data.cancelled.aggregate?.sum?.price ?? 0
                }
              />
            </Link>
            <Link href="/orders#past" className="w-full">
              <DataCard
                data={fetching ? '---' : data.completed.aggregate?.count ?? 0}
                name="Completed Orders"
                price={
                  fetching ? '---' : data.completed.aggregate?.sum?.price ?? 0
                }
              />
            </Link>
          </>
        )}
      </div>
    </div>
  )
}

const ProfileViews = () => {
  const { account } = useAuth()
  const [state, setState] = useState<{
    value: string
    whereCreatedAt: Timestamp_Comparison_Exp
  }>({ value: 'Today', whereCreatedAt: filterDateHandler('Today') })
  const [{ data, error, fetching }] = useProfileMetricsQuery({
    variables: {
      userID: account?.id,
      whereCreatedAt: state.whereCreatedAt,
      type: Wui_SessionEventType_Enum.PageView,
    },
  })

  if (error) {
    return (
      <div className="text-center">
        <ErrorMessage>{error.message}</ErrorMessage>
      </div>
    )
  }

  return (
    <div>
      <div className="flex items-center justify-between w-full py-2">
        <div className="text-lg font-bold">Profile</div>
        <div className="w-32">
          <FormDropDown
            $disableLabel
            $type="normal"
            value={state.value}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              const { value } = e.target

              setState({
                value,
                whereCreatedAt: filterDateHandler(e.target.value),
              })
            }}
          >
            {DASHBOARD_CATEGORIES.map((option) => (
              <option value={option} key={`profile-views-${option}`}>
                {option}
              </option>
            ))}
          </FormDropDown>
        </div>
      </div>
      <DataCard
        data={
          fetching
            ? '---'
            : data?.WUI_SessionEvent_aggregate.aggregate?.count || 0
        }
        name="Page Views"
      />
    </div>
  )
}

const friendlyDemographic = (demographic: string) => {
  return (
    patternMatching<string, string>([
      ['audience_size', 'Audience'],
      ['average_engagement', 'Engagement'],
      ['average_likes_per_post', 'Likes/post'],
      ['audience_ages', 'Age'],
    ])(demographic) || ''
  )
}

const UserSwiper = ({
  data,
}: {
  data: GetClientsQuery['WUI_Order'] | GetHiredUsersQuery['WUI_Order']
}) => {
  const router = useRouter()

  return (
    <Breakpoint>
      {(matches) => (
        <Swiper slidesPerView={matches.xl ? 3.2 : 1.8} spaceBetween={8}>
          {data.map((value, index: number) => {
            const WUI_User =
              'WUI_User_Seller' in value
                ? value.WUI_User_Seller
                : value.WUI_User_Buyer

            return (
              <SwiperSlide key={`influencer-${index}`}>
                <InfluencerCard
                  profile={{
                    id: WUI_User.id,
                    firstName: WUI_User.first_name,
                    lastName: WUI_User.last_name,
                    fullName: WUI_User.full_name,
                    entityName: WUI_User.entity_name,
                    picture: WUI_User.WUI_File_Profile_Picture?.url,
                    publicName: WUI_User.public_name,
                  }}
                  social_media={uniqBy(
                    WUI_User.SocialMediaDemographics.filter(
                      ({ source }) => source !== 'general',
                    ),
                    'source',
                  ).map(({ source }) => source)}
                  highlights={take(
                    2,
                    WUI_User.SocialMediaDemographics.filter(
                      ({ source }) => source === 'general',
                    ).filter(({ demographic }) =>
                      [
                        'audience_size',
                        'average_engagement',
                        'average_likes_per_post',
                        'audience_ages',
                      ].includes(demographic),
                    ),
                  ).map(({ demographic, value }) => ({
                    title: friendlyDemographic(demographic),
                    value,
                  }))}
                  onClick={() => {
                    router.push(`/user/${WUI_User.username}`)
                  }}
                />
              </SwiperSlide>
            )
          })}
        </Swiper>
      )}
    </Breakpoint>
  )
}

// const Links = () => {
//   const { account } = useAuth()

//   return (
//     <SimpleQuery
//       query={GetSocialMediaLinksDocument}
//       variables={{ userID: account?.id }}
//       Success={({ data }: { data: GetSocialMediaLinksQuery }) => {
//         const SocialMediaLink = [
//           ...data.SocialMediaLink.filter((link) => link.primary),
//           ...data.SocialMediaLink.filter((link) => !link.primary),
//         ]

//         if (SocialMediaLink.length === 0) {
//           return null
//         }

//         return (
//           <div>
//             <div>
//               <div className="flex items-center justify-between w-full py-2">
//                 <div className="text-lg font-bold">Clicks</div>
//                 <div className="w-32">
//                   <FormDropDown $disableLabel $type="normal">
//                     {DASHBOARD_CATEGORIES.map((option) => (
//                       <option value={option} key={`audience_ages-${option}`}>
//                         {option}
//                       </option>
//                     ))}
//                   </FormDropDown>
//                 </div>
//               </div>
//             </div>

//             <LinksCard links={SocialMediaLink} />
//           </div>
//         )
//       }}
//     />
//   )
// }
